<template>
  <div class="w-full">
    <notifications position="bottom right" />
    <div v-if="mode === 'login'" class="p-10">
      <div class="h-1/2 block"></div>
      <div class="text-lg">
        <a class="underline mr-3 font-semibold">LOG IN</a>
      </div>
      <div class="my-5 flex flex-col gap-2 text-sm">
        <p
          class="
            w-full
            text-center
            py-1
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:bg-gray-900 hover:text-white
            cursor-pointer
          "
          :class="[
            target == 'ETH' ? 'bg-black text-white' : 'bg-white text-black',
          ]"
          @click="
            target = 'ETH';
            metamaskLogin();
          "
        >
          Metamask
        </p>
        <p
          class="
            w-full
            text-center
            py-1
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:bg-gray-400 hover:text-white
            cursor-pointer
          "
          :class="[
            target == 'walletconnect'
              ? 'bg-gray-500 text-white'
              : 'bg-white text-black',
          ]"
          @click="
            target = 'walletconnect';
            connectUserWallet();
          "
        >
          Wallet Connect
        </p>
        <!-- <p
          class="
            w-full
            text-center
            py-1
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:bg-gray-400 hover:text-white
            cursor-pointer
          "
          :class="[
            target == 'walletlink'
              ? 'bg-gray-500 text-white'
              : 'bg-white text-black',
          ]"
          @click="
            target = 'walletlink';
            other();
          "
        >
          Wallet Link
        </p>
        <p
          class="
            w-full
            text-center
            py-1
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:bg-gray-400 hover:text-white
            cursor-pointer
          "
          :class="[
            target == 'Kaikas'
              ? 'bg-gray-500 text-white'
              : 'bg-white text-black',
          ]"
          @click="
            target = 'Kaikas';
            other();
          "
        >
          Kaikas
        </p> -->
      </div>
      <div
        class="
          text-red-600
          font-inter
          text-xs
          my-5
          transition
          duration-150
          ease-in-out
        "
      >
        {{ error_msg }}
      </div>
      <div>
        <router-link to="/" class="button home text-sm font-semibold"
          ><span class="mr-2">&#60;</span> Back to Home</router-link
        >
      </div>

      <div class="mt-16 text-gray-500 text-sm font-extralight">
        No Accounts?<router-link
          to="/authority?mode=signup"
          class="pl-3 font-semibold hover:underline hover:text-black"
          @click="onChangeMode"
          >Sign Up</router-link
        >
      </div>
    </div>
    <ResendMail
      v-else-if="mode == 'resend'"
      @onExit="
        mode = 'login';
        error_msg = '';
      "
    />
    <div v-else class="w-full h-60 items-center justify-center">
      <h>invalid mode</h>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import Caver from "caver-js";

import jwt_decode from "jwt-decode";
import OpenapiAuth from "../../../services/openapiAuth.service";
import SessionService from "../../../services/session.service.js";
import ResendMail from "./ResendMail";

//-- For walletconnect
import connect from '../../../composables/connect/index';
import { ethers} from "ethers";
import { checkMetamaskInstallation } from "@/util/wallet";
//!--

export default {
  name: "login",
  data: function() {
    console.log("##", "default.data");
    localStorage.removeItem("userState");
    localStorage.removeItem("walletconnect");
    
    return {
      target: "",
      mode: "login",
      checkSign: false, //가입여부 확인 데이터(API확인후 반영)
      error_msg: "",
    };
  },

  computed: {
    web3() {
      // return this.$store.state.web3
      //
      let web3 = new Web3(window.web3.currentProvider);
      return web3;
    },
  },

  components: {
    ResendMail,
  },



  methods: {
    updated: function() {
      console.log("@@@@", "updated");
    }, 

    onChangeMode: function() {
      this.$emit("changeMode", "signup");
    },

    close: function() {
      this.$emit("changeHidden");
    },

    other: function() {
      this.error_msg = "Not Support Yet";
    },

    // mMetamask: async function() {
    //   this.error_msg = "";
    //   this.login();
    // },

    checkSession(_address) {
      const session = SessionService.loadSession();
      // if (Object.keys(this.session).length < 1){
      OpenapiAuth.walletDoubleCheck(_address).then((hasAddr) => {
        //해당주소 가입여부 확인
        console.log("checked 결과:", hasAddr);

        if (!hasAddr || !hasAddr.data || hasAddr.data[0].account === null) {
          console.log("등록된 계정이 없음--> go signup");
        } else {
          if (SessionService.isExpired(session.lastTime)) {
            console.log("유효기간이 만료됨");
            this.caseConnectWallet(hasAddr.data[0]);
          } else {
            console.log("로그인된 고객");
            this.caseSessionAlive(hasAddr.data[0]);
          }
        }
      });
    },

    async walletLogin(platform, account, msgParams, signedMessage) {
      this.error_msg = "";
      await SessionService.clear();

      OpenapiAuth.walletLogin(platform, account, msgParams, signedMessage)
        .then((res) => {
          const token = res.data.token;
          const jwt = jwt_decode(token);

          SessionService.setToken(token);
          SessionService.setItems({
            platform: jwt.platform,
            // address: jwt.account,
            // wallet: jwt.wallet, // metamask, kaikas
            wallet: jwt.account,
            nickname: jwt.nickname,
            avatar: jwt.avatar,
            userId: jwt.user_id,
            mail: jwt.mail,
            msg: msgParams,
            sig: signedMessage,
          });

          // 임시: 로그인 후 새로고침.
          this.$router.push("/").then(() => {
            this.$router.go();
          });
        })
        .catch((error) => {
          let error_msg;
          if (error.response && error.response.data) {
            error_msg = error.response.data;
          }

          const http_status = error.response.status;
          console.log("##ERR13159: ", http_status, error);
          if (http_status == 401) {
            this.error_msg = error_msg;
          } else if (http_status == 404) {
            this.error_msg = `
              Your wallet address "${account}" is not registered.
              Please log in with your connected wallet. Please sign up first if you have not yet signed up.
            `;
          } else if (http_status == 412) {
            this.mode = "resend";
          } else {
            console.error(error_msg);
            this.error_msg = "service is not available for unknown reasons.";
          }
        });
    },

    async kaikasLogin() {
      const { klaytn } = window;
      const caver = new Caver(klaytn);

      this.error_msg = "";

      if (typeof klaytn !== "undefined") {
        try {
          const accounts = await klaytn.enable();
          const account = accounts[0];

          let expireDate = new Date();
          // expire date add 1day
          expireDate.setDate(expireDate.getDate() + 1);
          const msgParams = `login ${account} ${Date.parse(expireDate)}`;
          const signedMessage = await caver.klay.sign(msgParams, account);

          await this.walletLogin("KLAY", account, msgParams, signedMessage);
        } catch (error) {
          console.log(error);
        }
      } else console.error("Error 424: Can't find kaikas on your browser");
    },

    async metamaskLogin() {
      //href="https://metamask.io/download.html"

      this.error_msg = "";
      const metamask = await checkMetamaskInstallation();

      console.log("@@", "metamask", metamask);
      if (!metamask) {
        var win = window.open("https://metamask.io/download.html");        
        return;
      }
      const permissions = await window.ethereum.request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
      });

      console.log("permissions: ", permissions);

      console.log(
        "permissions[0].caveats[0].value.length: ",
        permissions[0].caveats[0].value.length !== 1
      );

      if (permissions[0].caveats[0].value.length !== 1) {
        console.error("Permission wallet must be one.");
        return;
      }

      const accountsPermission = await permissions.find(
        (permission) => permission.parentCapability === "eth_accounts"
      );

      if (!accountsPermission) {
        console.error("eth_accounts permission has not requested!");
        return;
      }

      const web3 = new Web3(window.web3.currentProvider);
      const from = await web3.eth.getAccounts();
      const account = from[0];

      let expireDate = new Date();
      // expire date add 1day
      expireDate.setDate(expireDate.getDate() + 1);
      const msgParams = `login ${account} ${Date.parse(expireDate)}`;

      let strMethod = "personal_sign";
      let paramValue = [msgParams, account];

      const res = await web3.currentProvider.send(strMethod, paramValue);
      const signedMessage = res.result;

      await this.walletLogin("ETH", account, msgParams, signedMessage);
    },

    //-- For walletconnect
    async connectUserWallet() {
      console.log("##connectUserWallet");
      const { connectWalletConnect, disconnectWallet, state } = connect();

      const result = await connectWalletConnect('login');
      if (state.status) {
        console.log("connected..");
        console.log("##", "Address:", state.address, state.status);

        const account  = state.address;
        const msgParams = result.m;
        const signedMessage = result.s;
        console.log("##", "Walletconnect", account, msgParams, signedMessage);

    //--- verify (for debugging)
        const verified = ethers.utils.verifyMessage(msgParams, signedMessage);
        console.log("##connectUserWallet", 
            "verifyed:", account,
            verified.toUpperCase(),
          );
    //!-- verify

        console.log("##", "Walletconnect", account, msgParams, signedMessage);
        await this.walletLogin("walletconnect", account, msgParams, signedMessage);
      } else {
        console.log("reject..");
      }
      console.log("##", "done.");
    },
    //!-- For walletconnect    

    // async login() {
    //   await SessionService.clear();

    //   const permissions = await window.ethereum.request({
    //     method: "wallet_requestPermissions",
    //     params: [{ eth_accounts: {} }],
    //   });

    //   const accountsPermission = await permissions.find(
    //     (permission) => permission.parentCapability === "eth_accounts"
    //   );

    //   if (!accountsPermission) {
    //     console.log("eth_accounts permission successfully requested!");
    //   }

    //   const web3 = new Web3(window.web3.currentProvider);
    //   const from = await web3.eth.getAccounts();
    //   const login_wallet = from[0];
    //   console.log("login_wallet::", login_wallet);

    //   var expireDate = new Date();
    //   // expire date add 1day
    //   expireDate.setDate(expireDate.getDate() + 1);
    //   const msgParams = `login ${login_wallet} ${Date.parse(expireDate)}`;

    //   let strMethod = "personal_sign";

    //   let paramVals = [msgParams, login_wallet];
    //   console.log("paramVals", paramVals);

    //   const res = await web3.currentProvider.send(strMethod, paramVals);
    //   const signature = res.result;

    //   console.log("signature: ", signature);

    //   // 아래의 코드에서 walletLogin을 할 때, platform을 같이 보내고 있다.
    //   OpenapiAuth.walletLogin("ETH", login_wallet, msgParams, res.result)
    //     .then((res) => {
    //       const token = res.data.token;
    //       console.log("token: ", token);
    //       const jwt = jwt_decode(token);

    //       SessionService.setToken(token);
    //       SessionService.setItems({
    //         platform: jwt.platform,
    //         wallet: jwt.account,
    //         nickname: jwt.nickname,
    //         avatar: jwt.avatar,
    //         userId: jwt.user_id,
    //         mail: jwt.mail,
    //         msg: msgParams,
    //         sig: signature,
    //       });

    //       // TODO 세션 & 로컬 스토리지 스터디 후 수정.
    //       // 임시: 로그인 후 새로고침.

    //       // this.$router.push("/").then(() => {
    //       //   this.$router.go();
    //       // });
    //     })
    //     .catch((error) => {
    //       let error_msg;
    //       if (error.response && error.response.data) {
    //         error_msg = error.response.data;
    //       }

    //       const http_status = error.response.status;
    //       console.log("##ERR13159: ", http_status, error);
    //       if (http_status == 401) {
    //         this.error_msg = error_msg;
    //       } else if (http_status == 404) {
    //         this.error_msg = `
    //           Your wallet address "${login_wallet}" is not registered.
    //           Please log in with your connected wallet. Please sign up first if you have not yet signed up.
    //         `;
    //       } else if (http_status == 412) {
    //         this.mode = "resend";
    //       } else {
    //         console.error(error_msg);
    //         this.error_msg = "Service is not available for unknown reasons.";
    //       }
    //       // notify({type:'warn', title:'Login fail', text: error_msg});
    //     });
    // },

    caseSessionAlive: async function(acc) {
      console.log("caseConnectWallet!!!!");
      if (typeof window.ethereum !== "undefined") {
        ethereum.eth_requestAccounts;
        const connetWallet = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        ethereum.request({ method: "eth_chainId" });
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });

        //세션정보 저장
        console.log("세션정보 저장");
        SessionService.setItems({
          platform: chainId,
          wallet: connetWallet[0],
          nickname: acc.nickname,
          avatar: acc.avatar,
          userId: acc.user_id,
          mail: acc.mail,
        });

        this.$router.push("/");
        return connetWallet;
      }
    },

    onConnect: function(event) {
      //1. 지갑주소가 가입되었는지 여부 확인
      //2-1. 가입되어있을때 바로 메타마스크 연결
      //2-2. 가입 안되어있을때
      //3. 프로필 입력컴포넌트

      if (this.checkSign === false) {
        //가입안되었을때
        console.log("this.checkSign" + this.checkSign);

        return;
      } else {
        const msgParams = "hello";
        return new Promise(function(resolve, reject) {
          if (typeof window.ethereum !== "undefined") {
            ethereum.eth_requestAccounts;
            const connetWallet = window.ethereum.request({
              method: "eth_requestAccounts",
            });
            console.log("연결완료");
            resolve(connetWallet);
          }
        })
          .then(async function(connetWallet) {
            // event.preventDefault()
            console.log("연결지갑주소" + connetWallet);
            // const msgParams ='hello'

            let web3 = new Web3(window.web3.currentProvider);
            let strMethod = "personal_sign";
            let from = await web3.eth.getAccounts();
            if (!from[0]) return this.onConnect();

            let paramVals = [msgParams, from[0]];
            console.log("params:", strMethod, paramVals, from[0]);

            // this.web3.currentProvider.sendAsync(method, params, from, function (err, result) {
            //   console.log('result:', result)
            // })

            let res = await web3.currentProvider.send(strMethod, paramVals);

            // let res = await ethereum.request({ method: strMethod , params: paramVals})

            console.log("서명결과:", res);
            res.address = connetWallet;
            return res;
          })
          .then(function(res) {
            console.log("연결지갑주소2" + res);
            let a = JSON.stringify(res);
            console.log(res.address.toString());
            let addr = res.address.toString();
            console.log("a" + a);
            // const paramApi = {
            //   "network":"network",
            //   // "account":res.address,
            //   "account": "0x122feb0fc838540a278ad23a8a5b3b232d476087",
            //   "user_id":"user_id",
            //   "chainid":"chainid",
            //   "alias1":"alias1",
            //   "alias2":"alias2",
            //   "avata":"avata.jpg",
            //   "msg":msgParams,
            //   "sig":res.result,
            //   "tag":"tag"
            // }
            console.log("addr" + addr);
            console.log("res.result.toString()" + res.result.toString());
            // console.log(msgParams[0].name)

            //    //지갑 주소 존재여부확인 없으면 가입해야함
            //     WalletServices.check(addr).then(res=>{
            //       console.log('결과값111:',res)
            //         }).catch(e=>{
            //           alert('error')
            //         })
            //     //지갑정보 insert 회원정보 입력하는 api 이게 입력 되면 check가 값 addr이 null이 아님
            //     WalletServices.insert('network', addr, 'user_id', 'chainid', 'alias1', 'alias2', 'avata.jpg', msgParams, res.result.toString(),'tag').then(res=>{
            //     console.log('결과값:',res)
            //       }).catch(e=>{
            //         alert('error')
            //       })
          });
      }
    },
  },
};
</script>
<style scoped>
.home {
  color: #7b848c;
}
</style>
