<template>
  <div class="w-full h-full">
    <div class="w-full items-center">
      <div class="px-5">
        <div class="text-lg">Account Confirmation Needed</div>
        <div class="mt-5">
          <p class="font-inter text-sm">
            Confirmation of your account is needed. In order to increase your
            account security and give you access to the CILO marketplace you
            must complete the sign up confirmation process through your
            email.<br />
            If you have not received a confirmation email, please check your
            spam or junk folder. Otherwise, please press the Resend Mail button
            below.
          </p>
        </div>
        <p
          class="
            text-red-600
            font-light
            text-sm
            my-5
            transition
            duration-150
            ease-in-out
          "
        >
          {{ error_msg }}
        </p>
        <div class="w-full flex justify-end my-5">
          <button
            class="
              text-center
              py-1
              px-5
              text-black
              transition-colors
              duration-150
              border border-black
              focus:shadow-outline
              hover:bg-black hover:text-white
            "
            @click="onResend"
          >
            Resend Mail (Metamask)
          </button>
          <button
            class="
              text-center
              py-1
              px-5
              text-black
              transition-colors
              duration-150
              border border-black
              focus:shadow-outline
              hover:bg-black hover:text-white
            "
            @click="onResendWalletConnect"
          >
            Resend Mail (WalletConnect)
          </button>
        </div>
        <br />
        <a href="javascript:;" @click="onExit" class="text-sm font-semibold"
          >&#60; back</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import OpenapiAuth from "../../../services/openapiAuth.service";

//-- For walletconnect
import connect from '../../../composables/connect/index';
import { ethers} from "ethers";
//!--

export default {
  data: function () {
    return {
      error_msg: "",
    };
  },

  methods: {
    onExit() {
      this.$emit("onExit");
    },

    async onResend() {
      const permissions = await ethereum.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      console.log("permissions::", permissions);

      const accountsPermission = await permissions.find(
        (permission) => permission.parentCapability === "eth_accounts"
      );
      console.log("accountsPermission", accountsPermission);
      if (!accountsPermission) {
        console.log("eth_accounts permission successfully requested!");
      }

      let web3 = new Web3(window.web3.currentProvider);
      let from = await web3.eth.getAccounts();
      const login_wallet = from[0];
      console.log("login_wallet::", login_wallet);

      var expireDate = new Date();
      // expire date add 1day
      expireDate.setDate(expireDate.getDate() + 1);
      const msgParams = `resend_confirm_mail ${login_wallet} ${Date.parse(
        expireDate
      )}`;

      let strMethod = "personal_sign";

      let paramVals = [msgParams, login_wallet];
      console.log("params:", strMethod, paramVals, login_wallet);

      const res = await web3.currentProvider.send(strMethod, paramVals);
      const signature = res.result;

      const stage = `${process.env.VUE_APP_STAGE}`;

      OpenapiAuth.walletResendMail(login_wallet, stage, msgParams, res.result)
        .then((res) => {
          this.error_msg = "";
          alert("Resend Mail Complete");
          this.onExit();
        })
        .catch((error) => {
          this.error_msg = error;
          if (error.response && error.response.data)
            this.error_msg = error.response.data;
        });
    },

    
    async onResendWalletConnect() {
      console.log("##onResendWalletConnect");
      const { connectWalletConnect, disconnectWallet, state } = connect();

      const result = await connectWalletConnect('resend_confirm_mail');
      if (state.status) {
        console.log("connected..");
        console.log("##onResendWalletConnect", "Address:", state.address, state.status);

        const account  = state.address;
        const msgParams = result.m;
        const signedMessage = result.s;
        console.log("##onResendWalletConnect", account, msgParams, signedMessage);

        //--- verify (for debugging)
        const verified = ethers.utils.verifyMessage(msgParams, signedMessage);
        console.log("##onResendWalletConnect", 
            "verifyed:", account,
            verified.toUpperCase(),
          );
        //!-- verify
        const stage = `${process.env.VUE_APP_STAGE}`;
        OpenapiAuth.walletResendMail(account, stage, msgParams, signedMessage)
          .then((res) => {
            this.error_msg = "";
            alert("Resend Mail Complete");
            this.onExit();
          })
          .catch((error) => {
            this.error_msg = error;
            if (error.response && error.response.data)
              this.error_msg = error.response.data;
          });        
      } else {
        console.log("reject..");
      }
      console.log("##", "done.");
    },
  },
};
</script>