<template>
  <div class="relative min-h-screen grid bg-white">
    <div
      class="
        flex flex-col
        sm:flex-row
        items-center
        md:items-start
        sm:justify-center
        md:justify-start
        flex-auto
        min-w-0
      "
    >
      <div
        class="
          relative
          sm:w-1/2
          xl:w-3/5
          bg-white
          h-full
          hidden
          md:flex
          flex-auto
          items-center
          justify-center
          p-10
          overflow-hidden
          text-white
          bg-no-repeat bg-cover
          relative
          login-bg
        "
      >
        <div class="absolute bg-black opacity-25 inset-0 z-0"></div>
        <div
          class="w-full lg:max-w-2xl md:max-w-md z-10 items-center text-center"
        >
          <div
            class="
              font-bold
              leading-tight
              mb-6
              mx-auto
              w-full
              content-center
              items-center
            "
          ></div>
        </div>
      </div>
      <div
        class="
          bg-white
          shadow-2xl
          md:flex md:items-center md:justify-left
          w-full
          sm:w-auto
          md:h-full
          xl:w-1/3
          p-8
          md:p-10
          lg:p-14
          sm:rounded-lg
          md:rounded-none
          xl:rounded-none
        "
      >
        <div class="max-w-xl w-full space-y-12">
          <WalletSignupForm v-if="mode === 'signup'" @changeMode="changeMode" />
          <WalletLoginForm v-if="mode === 'login'" @changeMode="changeMode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WalletLoginForm from "./sub/WalletLoginForm.vue";
import WalletSignupForm from "./sub/WalletSignupForm.vue";

export default {
  data: function () {
    return {
      mode: "",
    };
  },

  components: {
    WalletLoginForm,
    WalletSignupForm,
  },

  created: function () {
    this.mode = this.$route.query.mode;
  },

  methods: {
    changeMode: function (e) {
      this.mode = e;
    },
  },
};
</script>

<style scoped>
.sign-wrapper {
  width: 100vw;
  height: 100vh;
}
.login-bg {
  background-image: url("../../../src/assets/img/login.jpeg");
  background-position: center;
}
</style>
