<template>
  <div class="w-full items-center">
    <div class="form px-10 pb-10 space-y-12">
      <div class="underline mr-3 font-semibold text-lg">PROFILE</div>
      <div class="mt-5">
        <div class="flex flex-col mb-4">
          <label
            for="name"
            class="text-xs sm:text-sm tracking-wide text-gray-600"
          >
            Name</label
          >
          <input
            id="name"
            name="name"
            type="text"
            class="mt-3 border-b"
            :class="[
              { 'bg-red-50': !valid.name },
              { 'border-red-500': !valid.name },
              { 'animate-pulse': !valid.name },
            ]"
            placeholder="Name"
            v-model="inputName"
          />
        </div>
        <div class="flex flex-col mb-4">
          <label
            for="nickname"
            class="mb-1 w-24 text-xs sm:text-sm tracking-wide text-gray-600"
          >
            Nick name</label
          >
          <input
            id="nickname"
            name="nickname"
            type="text"
            class="mt-3 border-b"
            :class="[
              { 'bg-red-50': !valid.nickname },
              { 'border-red-500': !valid.nickname },
              { 'animate-pulse': !valid.nickname },
            ]"
            placeholder="Nick name"
            v-model="inputNickname"
          />
        </div>
        <!-- <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
			    Invalid nickname
            </span> -->
        <div class="flex flex-col mb-4">
          <label
            for="email"
            class="mb-1 w-24 text-xs sm:text-sm tracking-wide text-gray-600"
          >
            email</label
          >
          <input
            id="email"
            name="email"
            type="text"
            class="mt-3 border-b"
            :class="[
              { 'bg-red-50': !valid.email },
              { 'border-red-500': !valid.email },
              { 'animate-pulse': !valid.email },
            ]"
            placeholder="E-mail"
            v-model="inputEmail"
          />
        </div>
        <!-- <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
			    Invalid nickname
            </span> -->
        <!-- <input type="text" class="border-b" placeholder="URL" v-model="inputUrl"><br>
            <input type="text" class="border-b" placeholder="SNS" v-model="inputSns"><br> -->
        <!-- <input id="checkbox" type="checkbox" name="checktext" value="1" v-model="checkedNewsMail">
            <label for="checktext">Please sign me up for the newsletter.</label> -->
      </div>
      <!-- <button
            class="w-24 text-center py-1 text-black transition-colors duration-150 border border-black focus:shadow-outline hover:bg-black hover:text-white"
            @click="confirm">Confirm</button><br> -->

      <p
        class="
          text-red-600
          font-light
          text-sm
          my-5
          transition
          duration-150
          ease-in-out
        "
      >
        {{ error_msg }}
      </p>
      <!-- <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            </span> -->
      <div class="w-full flex justify-end my-5">
        <button
          class="
            w-24
            text-center
            py-1
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:bg-black hover:text-white
          "
          @click="onConfirm"
        >
          Confirm
        </button>
      </div>
      <br />
      <span class="cursor-pointer text-sm font-semibold" @click="onPrevious"
        >&#60; Previous</span
      >
    </div>
  </div>
</template>
<script>
import OpenapiAuth from "../../../services/openapiAuth.service";

import Web3 from "web3";
import Caver from "caver-js";

//-- For walletconnect
import connect from '../../../composables/connect/index';
import { ethers} from "ethers";

//!--

export default {
  props: {
    isLogins: Boolean,
    platform: {
      type: String,
      required: true,
    },
  },

  data: function() {
    return {
      inputName: "",
      inputNickname: "",
      inputEmail: "",
      inputUrl: "",
      inputSns: "",
      checkedNewsMail: [],
      error_msg: "",
      stage: "", //stage에 따라서 이메일발송 API가 달라짐.
      valid: {
        name: true,
        nickname: true,
        email: true,
      },
    };
  },

  computed: {
    web3() {
      // return this.$store.state.web3
      //
      let web3 = new Web3(window.web3.currentProvider);
      return web3;
    },
  },

  methods: {
    onPrevious: function() {
      this.$emit("isTermChange", true);
    },

    validation() {
      this.valid.name = true;
      this.valid.nickname = true;
      this.valid.email = true;

      const blank_pattern = /\s/;
      const engNumRegexp = /^[a-zA-Z0-9]*$/;
      const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      if (blank_pattern.test(this.inputName)) {
        this.error_msg = "The name not allow blank";
        this.valid.name = false;
        return false;
      }

      if (this.inputName.length < 3) {
        this.error_msg = "The name must be at least 3 letter.";
        this.valid.name = false;
        return false;
      }

      if (!engNumRegexp.test(this.inputName)) {
        this.error_msg = "The name can only contain letters and numbers.";
        this.valid.name = false;
        return false;
      }

      if (blank_pattern.test(this.inputNickname)) {
        this.error_msg = "The nickname not allow blank";
        this.valid.nickname = false;
        return false;
      }

      if (this.inputNickname.length < 3) {
        this.error_msg = "The nickname must be at least 3 letter.";
        this.valid.nickname = false;
        return false;
      }

      if (!engNumRegexp.test(this.inputNickname)) {
        this.error_msg = "The nick name can only contain letters and numbers.";
        this.valid.nickname = false;
        return false;
      }

      if (blank_pattern.test(this.inputEmail)) {
        this.error_msg = "The email not allow blank";
        this.valid.email = false;
        return false;
      }

      if (!emailRegexp.test(this.inputEmail)) {
        this.error_msg = "invalid mail format";
        this.valid.email = false;
        return false;
      }

      this.stage = `${process.env.VUE_APP_STAGE}`;
      return true;
    },

    onConfirm() {
      this.error_msg = "";

      if (!this.validation()) {
        return;
      }

      this.checkDuplicate();
    },

    checkDuplicate() {
      OpenapiAuth.walletDuplicateCheck(this.inputEmail, this.inputNickname)
        .then((res) => {
          if (res.data.msg === "ok") {
            if (this.platform === "ETH") {
              this.metamaskRegist();
            } else if (this.platform === "KLAY") {
              this.kaikasRegist();
            } else if (this.platform === "walletconnect") {
              this.walletconnectRegist();
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.msg)
            this.error_msg = error.response.data.msg;
        });
    },

    async kaikasRegist() {
      const { klaytn } = window;
      const caver = new Caver(klaytn);

      this.error_msg = "";

      if (typeof klaytn !== "undefined") {
        try {
          const accounts = await klaytn.enable();
          const account = accounts[0];

          let expireDate = new Date();
          // expire date add 1day
          expireDate.setDate(expireDate.getDate() + 1);
          const msgParams = `regist ${account} ${Date.parse(expireDate)}`;
          const signedMessage = await caver.klay.sign(msgParams, account);

          await this.walletRegist(account, msgParams, signedMessage);
        } catch (error) {
          console.log(error);
        }
      } else console.error("Error 424: Can't find kaikas on your browser");
    },

    async metamaskRegist() {
      if (typeof window.ethereum !== "undefined") {
        try {
          const permissions = await window.ethereum.request({
            method: "wallet_requestPermissions",
            params: [{ eth_accounts: {} }],
          });

          const accountsPermission = await permissions.find(
            (permission) => permission.parentCapability === "eth_accounts"
          );

          if (!accountsPermission) {
            console.log("eth_accounts permission successfully requested!");
          }
        } catch (error) {
          if (error.code === 4001) {
            console.error("Permissions needed to continue.");
            this.error_msg = "Permissions needed to continue.";
          } else {
            console.error(error);
            this.error_msg = error;
          }
        }
      } else console.error("Error 424: Can't find metamask on your browser");

      const web3 = new Web3(window.web3.currentProvider);
      const from = await web3.eth.getAccounts();
      const account = from[0];

      console.log("account::", account);

      const msgParams = `regist ${account} ${Date.parse(new Date())}`;

      let strMethod = "personal_sign";
      let paramValue = [msgParams, account];

      const res = await web3.currentProvider.send(strMethod, paramValue);
      const signedMessage = res.result;

      await this.walletRegist(account, msgParams, signedMessage);
    },

    //-- For walletconnect
    async walletconnectRegist() {

      console.log("##", "walletconnectRegist");
      const { connectWalletConnect, state } = connect();

      const result = await connectWalletConnect('regist');
      if (state.status) {
        console.log("connected..");
        console.log("##walletconnectRegist", "Address:", state.address, state.status);

        const account  = state.address;
        const msgParams = result.m;
        const signedMessage = result.s;

    //--- verify (for debugging)
        const verified = ethers.utils.verifyMessage(msgParams, signedMessage);
        console.log("##walletconnectRegist", 
            "verifyed:", account.toUpperCase(),
            verified.toUpperCase(),
          );
    //!-- verify

        console.log("##walletconnectRegist", "Walletconnect", account, msgParams, signedMessage);
        await this.walletRegist(account, msgParams, signedMessage);
      } else {
        console.log("##walletconnectRegist", "reject..");
      }
    },
    //!--

    walletRegist(account, msgParams, signedMessage) {
      console.log("##walletRegist", 
        this.inputName,
        this.inputNickname,
        this.inputEmail,
        this.stage,
        this.platform,
        account,
        msgParams,
        signedMessage
      );

      OpenapiAuth.walletRegist(
        this.inputName,
        this.inputNickname,
        this.inputEmail,
        this.stage,
        this.platform,
        account,
        msgParams,
        signedMessage
      )
        .then((res) => {
          console.log("walletUser결과:", res);
          this.$router.push("/welcome/" + JSON.parse(res.config.data).nickname);
        })
        .catch((error) => {
          this.error_msg = error;
          if (error.response && error.response.data)
            this.error_msg = error.response.data.msg;
        });
    },
  },
};
</script>
<style scoped></style>
